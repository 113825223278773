<template>
  <div>
    <b-row>
      <b-col
        class="mb-2"
        cols="12"
      >
        <h5 class="mb-0">
          {{ $t('message.activity_title') }}
          <br>
          <small
            class="text-muted"
          >{{ $t('message.activity_desc') }}
          </small>
        </h5>
      </b-col>
    </b-row>
    <b-row
      v-for="(row, index) in descriptionInput"
      :key="index"
    >
      <b-col
        v-for="(singleInput, loopIndex) in row.singleRow.rowInputs"
        :key="loopIndex"
        :md="singleInput.md"
      >
        <GlobalSingleInput :input="singleInput" :readOnly="readOnly" />
      </b-col>
    </b-row>

    <div>
      <h5>{{ $t('label.activities') }}</h5>
      <draggable v-model="controls" tag="ul" class="draggable-list" :disabled="readOnly">
        <li v-for="(control, idx) in controls" :key="idx" class="draggable-item">
          <feather-icon icon="MenuIcon" class="menu-icon" />
          <span class="mr-1">{{ `${idx + 1}.` }}</span>
          <v-select
            v-model="controls[idx]"
            class="control-select"
            label="title"
            :options="controlOptions"
            :reduce="(option) => option.value"
            :placeholder="$t('placeholder.controlSelect')"
            :append-to-body="true"
            :selectable="(option) => !controls.includes(option.value)"
            :disabled="readOnly"
          />
          <small v-show="!control" class="text-danger ml-1">{{ validationMessage }}</small>
          <b-button variant="outline-danger" class="ml-2" :disabled="readOnly" @click="removeControl(idx)">
            <feather-icon icon="XIcon" class="mr-25" />
            <span>{{ $t('message.delete') }}</span>
          </b-button>
        </li>
      </draggable>
    </div>

    <b-button class="mt-1" variant="primary" :disabled="readOnly" @click="addControl">
      <feather-icon icon="PlusIcon" class="mr-25" />
      <span>{{ $t('message.Add_New_Activity') }}</span>
    </b-button>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput.vue'
import { convertInputsToKeyValuePair } from '@/helpers/objectHelper'
import i18n from '@/libs/i18n'
import { set, ref, onMounted, computed } from '@vue/composition-api'
import draggable from 'vuedraggable'
import vSelect from 'vue-select'
import realmConnection from '@/views/habit/realm'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'

export default {
  directives: {
    Ripple,
  },
  components: {
    GlobalSingleInput,
    draggable,
    vSelect,
  },
  mixins: [heightTransition],
  props: {
    data: { type: Object, default: {} },
    readOnly: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const { showErrorMessage } = useNotifications()
    const { getItems, ObjectId } = realmConnection()
    const userData = store.state?.userStore?.userData
    const controls = ref([])
    const controlOptions = ref([])
    const validationMessage = computed(() => i18n.t('label.required'))

    const addControl = () => {
      controls.value.push('')
    }

    const removeControl = (idx) => {
      controls.value.splice(idx, 1)
    }

    onMounted(async () => {
      try {
        const query = {
        client_id: ObjectId(userData?.client?.$oid),
        deleted: { $ne: true }
      }

        const items = await getItems({ collection: 'control', query, options: { sort: { name: 1 } } })
        controlOptions.value = items?.map(e => ({ title: e.name, value: String(e._id) })) || []
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.data_fetch_error'))
      }
    })

    return {
      controls,
      controlOptions,
      addControl,
      removeControl,
      validationMessage,
    }
  },
  data() {
    return {
      descriptionInput: [
        {
          singleRow: {
            rowAttributes: {},
            rowInputs: [
              {
                label: i18n.t('label.description'),
                type: 'textarea',
                key: 'description',
                rules: '',
                placeholder: i18n.t('placeholder.activity_desc'),
                value: '',
                description: i18n.t('message.process_desc'),
              },
            ],
          },
        },
      ],
      items: [
        {
          singleRow: {
            rowAttributes: {},
            rowInputs: [
              {
                md: 8,
                label: i18n.t('message.activity_no_1'),
                notDeletedIndex: 0,
                type: 'text',
                key: 'activity_1',
                rules: 'required',
                placeholder: i18n.t('placeholder.activity'),
                value: '',
              },
            ],
          },
        },
      ],
    }
  },
  watch: {
    data: {
      handler(value) {
        this.descriptionInput[0].singleRow.rowInputs[0].value = value.description
        if (value.list) {
          this.controls = value.list
          // this.items = []
          // value.list.forEach((activity, index) => {
          //   this.repeatAgain()
          //   this.items[index].singleRow.rowInputs[0].value = activity
          // })
          // this.trAddHeight(value.list.length * 40)
        }
      },
      deep: true,
      immediate: true,
    },
    items: {
      handler(value) {
        this.$emit('updActivities', value)
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeatAgain() {
      const notDeletedIndex = this.items.filter(e => !e.singleRow.rowInputs[0].deleted).length
      this.items.push({
        singleRow: {
          rowAttributes: {},
          rowInputs: [
            {
              md: 8,
              label: i18n.tc('message.activity_no', 0, { count: `${notDeletedIndex + 1}` }),
              notDeletedIndex,
              type: 'text',
              key: `activity_${this.items.length + 1}`,
              rules: 'required',
              placeholder: i18n.t('placeholder.activity'),
              value: '',
            },
          ],
        },
      })

      // this.$nextTick(() => {
      //   if (this.items.length === 1) { this.trAddHeight(this.$refs.row[0].offsetHeight + 10) } else this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeItem(index) {
      set(this.items[index].singleRow.rowInputs[0], 'deleted', true)
      
      if (this.items.length) this.trTrimHeight(this.$refs.row[0].offsetHeight)
      else this.initTrHeight()

      // Update label and notDeletedIndex of activities
      // If there are no more activities, add a new blank activity
      const activitiesNotDeleted = this.items.filter(e => !e.singleRow.rowInputs[0].deleted).length

      if (!activitiesNotDeleted) this.repeatAgain()
      else {
        let notDeletedIndex = 0
        this.items.forEach(e => {
          if (!e.singleRow.rowInputs[0].deleted) {
            e.singleRow.rowInputs[0].label = i18n.tc('message.activity_no', 0, { count: `${notDeletedIndex + 1}` })
            e.singleRow.rowInputs[0].notDeletedIndex = notDeletedIndex
            notDeletedIndex++
          }
        })
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(
      //     this.$refs.form.scrollHeight + this.items.length === 0 ? 0 : 90,
      //   )
      // })
    },
    getData() {
      return {
        activities: {
          ...convertInputsToKeyValuePair(this.descriptionInput),
          list: this.controls,
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.draggable-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.draggable-item {
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.draggable-item:hover {
  background-color: #f9f9f9;
}

.menu-icon {
  margin-right: 14px;
  cursor: move;
}

.control-select {
  flex-grow: 1;
}
</style>
