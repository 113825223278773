<template>
  <div>
    <b-row
      v-for="(row,index) in inputs"
      :key="index"
    >
      <b-col
        v-for="(singleInput,loopIndex) in row.singleRow.rowInputs"
        :key="loopIndex"
        :md="singleInput.md"
      >
        <GlobalSingleInput
          :key="loopIndex"
          :input="singleInput"
          :readOnly="readOnly"
        />
      </b-col>
    </b-row>

    <b-row v-if="selectedCrimes.length">
      <b-col cols="12">
        <label>{{ $t('label.crimesDetails') }}</label>
        <b-table
          ref="refCrimeListTable"
          hover
          responsive
          primary-key="id"
          show-empty=""
          :items="selectedCrimes"
          :fields="tableColumns"
        >
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { ref, reactive, watch, computed } from '@vue/composition-api'
import GlobalSingleInput from '@/views/components/form/GlobalSingleInput.vue'
import { convertInputsToKeyValuePair } from '@/helpers/objectHelper'
import _ from 'lodash'
import i18n from '@/libs/i18n'

export default {
  name: i18n.t('label.identification'),
  components: {
    GlobalSingleInput,
    BTable,
  },
  props: ['data', 'crimes', 'crimesData', 'areas', 'readOnly'],
  setup(props) {
    const inputs = reactive([
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 6,
                  label: i18n.t('label.Name'),
                  type: 'text',
                  key: 'name',
                  rules: 'required',
                  placeholder: i18n.t('placeholder.name'),
                  value: '',
                },
                {
                  md: 6,
                  label: i18n.t('label.code'),
                  type: 'text',
                  key: 'code',
                  rules: '',
                  placeholder: i18n.t('placeholder.code'),
                  value: '',
                },
              ],
            },
      },
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 6,
                  label: i18n.t('label.crimes'),
                  type: 'select',
                  key: 'crimes',
                  rules: '',
                  placeholder: i18n.t('placeholder.crimes'),
                  value: [],
                  options: props.crimes,
                  multiple: true
                },
                {
                  md: 6,
                  label: i18n.t('label.operationalAreas'),
                  type: 'select',
                  key: 'areas',
                  rules: '',
                  placeholder: i18n.t('placeholder.operationalAreas'),
                  value: [],
                  options: props.areas,
                  multiple: true
                }
              ],
            },
      },
      {
        singleRow:
            {
              rowAttributes: {},
              rowInputs: [
                {
                  md: 12,
                  label: i18n.t('label.goal'),
                  type: 'textarea',
                  key: 'goal',
                  rules: '',
                  placeholder: i18n.t('placeholder.goal'),
                  value: '',
                  description: '',
                },
                // {
                //   md: 6,
                //   label: i18n.t('label.assumptions'),
                //   type: 'textarea',
                //   key: 'assumptions',
                //   rules: '',
                //   placeholder: i18n.t('placeholder.assumptions'),
                //   value: '',
                //   description: '',
                // },
              ],
            },
      },
    ])

    const getData = () => ({ identification: convertInputsToKeyValuePair(inputs) })

    const selectedCrimes = ref([])

    const tableColumns = computed(() => {
      return [
        { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
        { key: 'lawNumber', label: i18n.t('label.lawNumber'), sortable: true },
        { key: 'law', label: i18n.t('label.lawName'), sortable: true },
        { key: 'article', label: i18n.t('label.article'), sortable: true },
        { key: 'number', label: i18n.t('label.number'), sortable: true },
        { key: 'description', label: i18n.t('message.tableHeader.description'), sortable: true },
      ]
    })

    const updateSelectedCrimesData = (crimes, crimesData) => {
      if (crimes?.length && crimesData?.length) {
        const crimesIds = crimes.map(crime => crime.value)
        selectedCrimes.value = crimesData.filter(crime => crimesIds.includes(crime._id))
      } else {
        selectedCrimes.value = [] // Clear table if no crimes are selected
      }
    }

    // Watch the selected crimes input field
    watch(() => inputs[1].singleRow.rowInputs.find(input => input.key === 'crimes').value,
      crimes => updateSelectedCrimesData(crimes, props.crimesData),
      { deep: true }
    )

    // Watch the crimes data because it may be loaded after the selected crimes are set
    watch(() => props.crimesData, crimesData => {
      const crimes = inputs[1].singleRow.rowInputs.find(input => input.key === 'crimes').value

      updateSelectedCrimesData(crimes, crimesData)
    }, { deep: true, immediate: true })

    watch(() => props.crimes, data => {
      inputs[1].singleRow.rowInputs[0].options = data
    }, { deep: true, immediate: true })

    watch(() => props.areas, data => {
      inputs[1].singleRow.rowInputs[1].options = data
    }, { deep: true, immediate: true })

    watch(() => props.data, data => {
      if (data) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(data)) {
          inputs.forEach(row => {
            if (_.find(row.singleRow.rowInputs, { key })) {
              _.find(row.singleRow.rowInputs, { key }).value = value
            }
          })
        }
      }
    }, { deep: true, immediate: true })

    return {
      inputs,
      getData,
      selectedCrimes,
      tableColumns,
    }
  },
}
</script>

<style scoped>

</style>
